import {Controller} from '@hotwired/stimulus'

export default class extends Controller {
    connect() {
        console.log('flash message controller connected')
        setTimeout(() => {
            this.element.classList.add('animate-fade-out')
        }, 3000)
        setTimeout(() => {
            this.element.remove()
        }, 4000) // Remove flash message after 5 seconds
    }

    close() {
        this.element.remove()
    }
}