import Chart from 'chart.js/auto';
import { Controller } from "@hotwired/stimulus"

const config = {
    type: 'pie',
    data: JSON.parse('{ "labels": ["January", "February", "March", "April", "May", "June", "July"], "datasets": [{ "label": "Data", "backgroundColor": "transparent", "borderColor": "#3B82F6", "data": [37, 83, 78, 54, 12, 5, 99] }] }'),
    options: {
      responsive: true,
      plugins: {
        legend: {
          position: 'top',
        },
      }
    },
};


export default class extends Controller {
static targets = ["chart"]
  connect() {
    if (this.chartTarget) {
      const chart = new Chart(this.chartTarget, config);
    }
  }
}
