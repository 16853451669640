import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["form", "query", "searchInput"]

    connect() {
        console.log("Datatable controller connected")
        // When I load this controller, I want to make sure the search input has the value like in the URL.
        const searchValue = new URLSearchParams(window.location.search).get('search')
        if (searchValue && this.hasSearchInputTarget) {
          this.searchInputTarget.value = searchValue
          this.searchInputTarget.focus()
        }
    
        // Only add event listeners if targets exist
        if (this.hasFormTarget && this.hasQueryTarget) {
          this.boundHandleSubmit = this.handleSubmit.bind(this)
          this.boundHandleEnterKey = this.handleEnterKey.bind(this)
          
          this.formTarget.addEventListener('submit', this.boundHandleSubmit)
          this.queryTarget.addEventListener('keypress', this.boundHandleEnterKey)
        }
    
        // Handle select all checkbox if it exists
        const selectAllCheckbox = document.getElementById("select_all")
        const userCheckboxes = document.querySelectorAll(".user_checkbox")
        
        if (selectAllCheckbox && userCheckboxes.length > 0) {
          selectAllCheckbox.addEventListener("change", function () {
            userCheckboxes.forEach(checkbox => {
              checkbox.checked = selectAllCheckbox.checked
            })
          })
        }
      }

    handleEnterKey(event) {
        if (event.key === 'Enter') {
            this.handleSubmit(event);
        }
    }

    searchEntries(event) {
        // if input is Enter, then submit the form.
        console.log({event})
        if (event.key !== 'Enter') {
            return
        }
        const searchValue = event.target.value;
        // Get current params from the url and add ?search=searchValue
        const currentPath = window.location.pathname;
        const currentParams = new URLSearchParams(window.location.search);

        currentParams.set('search', searchValue);
        currentParams.set('search_type', "name");
        return window
            .location
            .href = `${currentPath}?${currentParams.toString()}`;
    }

    handleSubmit(event) {
        event.preventDefault();

        // Get values from the form.
        const searchValue = event.target.querySelector('#list-search').value;
        const searchType = event.target.querySelector('#list-search-type').value;

        // cuurent location
        const currentPathname = window.location.pathname;


        window.location.href = `${currentPathname}?search=${searchValue}&search_type=${searchType}`;
    }

    async loadSide(event) {
        const hasData = event?.target?.dataset?.action && event?.target?.dataset?.url;
        const hasParentData = event?.target?.parentElement?.dataset?.action && event?.target?.parentElement?.dataset?.url;

        console.log({hasData, hasParentData})
        if (!hasData && !hasParentData) {
            return false
        }

        const childUrl = event?.target?.dataset?.url
        const parentUrl = event.target?.parentElement?.dataset?.url

        const url = childUrl || parentUrl;


        if (event?.target?.dataset?.anchor === "true") {
            const link = event?.target?.dataset?.url
            // navigate to the link
            console.log("check2", link)
            window.location.href = link;
            return
        }


        event.preventDefault();

        try {
            const frameId = "data-frame";
            const response = await fetch(url, {
                headers: {
                    'Accept': 'text/vnd.turbo-stream.html, text/html, application/xhtml+xml',
                    'Turbo-Frame': frameId
                }
            });

            if (response.ok) {
                const turboStreamMessage = await response.text();
                Turbo.renderStreamMessage(turboStreamMessage);
            } else {
                console.error('Failed to load content');
            }
        } catch (error) {
            console.error('Failed to load content', error);
        }
    }

    disconnect() {
        // Only remove event listeners if they were added and targets still exist
        if (this.hasFormTarget && this.boundHandleSubmit) {
          this.formTarget.removeEventListener('submit', this.boundHandleSubmit)
        }
        
        if (this.hasQueryTarget && this.boundHandleEnterKey) {
          this.queryTarget.removeEventListener('keypress', this.boundHandleEnterKey)
        }
      }
}