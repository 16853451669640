document.addEventListener('turbo:load', () => {
    const dropdownButton = document.getElementById('dropdownButton');
    const dropdownMenu = document.getElementById('dropdownMenu');

    dropdownButton.addEventListener('click', () => {
        dropdownMenu.classList.toggle('hidden');
    });

    // hide dropdown when clicking outside
    document.addEventListener('click', (event) => {
        if (!dropdownMenu.contains(event.target) && !dropdownButton.contains(event.target)) {
            dropdownMenu.classList.add('hidden');
        }
    });


    const dropdownButtonUser = document.getElementById('dropdownButtonUser');
    const dropdownMenuUser = document.getElementById('dropdownMenuUser');

    if (dropdownButtonUser) {
        dropdownButtonUser.addEventListener('click', () => {
            dropdownMenuUser.classList.toggle('hidden');
        });
    }

    // hide dropdown when clicking outside
    document.addEventListener('click', (event) => {
        if (dropdownMenuUser && !dropdownMenuUser.contains(event.target) && !dropdownButtonUser.contains(event.target)) {
            dropdownMenuUser.classList.add('hidden');
        }
    });

    const dropdownButtonManage = document.getElementById('dropdownButtonManage');
    const dropdownMenuManage = document.getElementById('dropdownMenuManage');

    if (dropdownButtonManage) {
        dropdownButtonManage.addEventListener('click', () => {
            dropdownMenuManage.classList.toggle('hidden');
        });
    }

    // hide dropdown when clicking outside
    document.addEventListener('click', (event) => {
        if (dropdownMenuManage && !dropdownMenuManage.contains(event.target) && !dropdownButtonManage.contains(event.target)) {
            dropdownMenuManage.classList.add('hidden');
        }
    });
});