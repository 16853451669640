import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["map", "lastUpdated", "progress", "progressBar"]

    connect() {
        console.log("GPS controller connected")
        this.initializeMap()
        this.startLocationPolling()
        this.markers = [] // Array to keep track of markers
        this.initialZoomDone = false // Flag to track initial zoom
        this.progress = 0
    }

    initializeMap() {
        // Initialize the map (Netherlands centered)
        this.map = L.map(this.mapTarget).setView([52.1326, 5.2913], 7)

        // Add OpenStreetMap tiles
        L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
            maxZoom: 19,
        }).addTo(this.map)

        // Ensure proper sizing when map loads
        this.map.invalidateSize()

        L.Marker.prototype.slideTo = function(latlng, options) {
            const startLatLng = this.getLatLng();
            const startTime = performance.now();
            const duration = options?.duration || 1000;
        
            const animate = (currentTime) => {
                const elapsed = currentTime - startTime;
                const progress = Math.min(elapsed / duration, 1);
        
                // Easing function (ease-out)
                const eased = 1 - Math.pow(1 - progress, 3);
        
                const newLat = startLatLng.lat + (latlng[0] - startLatLng.lat) * eased;
                const newLng = startLatLng.lng + (latlng[1] - startLatLng.lng) * eased;
                
                this.setLatLng([newLat, newLng]);
        
                if (progress < 1) {
                    requestAnimationFrame(animate);
                }
            };
        
            requestAnimationFrame(animate);
        };
    }

    startLocationPolling() {
        // Fetch immediately on connect
        this.fetchLocations()
        this.startProgressBar()

        // Then fetch every 25 seconds
        this.pollingInterval = setInterval(() => {
            this.fetchLocations()
            this.startProgressBar()
        }, 25000) // 25 seconds in milliseconds
    }

    startProgressBar() {
        // Reset progress
        this.progress = 0
        this.updateProgressBar()

        // Clear any existing progress animation
        if (this.progressInterval) {
            clearInterval(this.progressInterval)
        }

        // Start new progress animation
        const startTime = Date.now()
        const duration = 25000 // 25 seconds

        this.progressInterval = setInterval(() => {
            const elapsed = Date.now() - startTime
            this.progress = Math.min((elapsed / duration) * 100, 100)
            this.updateProgressBar()
        }, 100) // Update every 100ms for smooth animation
    }

    updateProgressBar() {
        if (this.progressBarTarget) {
            this.progressBarTarget.style.width = `${this.progress}%`
        }
    }

    async fetchLocations() {
        console.log("Fetching locations")
        try {
            const response = await fetch('/admin/gps/poll', {
                headers: {
                    "Content-Type": "application/json"
                }
            })
            console.log("Fetching locations", response)

            const json = await response.json()
            this.updateMarkers(json)
            this.updateLastUpdated()
        } catch (error) {
            console.error('Error fetching locations:', error)
            console.error('Error details:', error.message)
        }
    }

    updateLastUpdated() {
        this.lastUpdatedTarget.textContent = new Date().toLocaleString()
        this.progressBarTarget.style.width = `${this.progress}%`
    }

    updateMarkers(locations) {
        const existingMarkers = new Map(this.markers.map(marker => [
            marker.getTooltip().getContent(), // use license plate as key
            marker
        ]));
        this.markers = [];

        // Define the combined triangle and circle icon
        const createIcon = (location) => L.divIcon({
            html: `
                <div style="
                    position: relative;
                    width: 20px;
                    height: 20px;
                    border-radius: 50%;
                    background-color: ${location.ignitionStatus === 1 ? '#2196F3' : '#9E9E9E'};
                    border: 2px solid #fff;
                    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
                    transform: rotate(${location.heading || 0}deg);
                ">
                    <div style="
                        position: absolute;
                        width: 16px;
                        height: 16px;
                        border-left: 6px solid transparent;
                        border-right: 6px solid transparent;
                        border-bottom: 8px solid black;
                        top: -13px;
                    "></div>
                </div>`,
            className: 'vehicle-marker',
            iconSize: [20, 20],
            iconAnchor: [10, 10], // This centers the icon on the actual location point
            popupAnchor: [0, -10] // This adjusts the popup position relative to the icon
        })

        if (locations.length === 0) {
            // Wait for 25 seconds before polling again.
            return
        }

        locations.forEach(location => {
            if (location.latitude && location.longitude) {
                const newLatLng = [location.latitude, location.longitude];
                const existingMarker = existingMarkers.get(location.licensePlate);

                if (existingMarker) {
                    // Update existing marker's position and rotation smoothly
                    existingMarker.slideTo(newLatLng, {
                        duration: 2000, // 2 seconds transition
                        keepAtCenter: false
                    });

                    // Update the icon's rotation
                    const iconElement = existingMarker.getElement()?.querySelector('div > div:last-child');
                    if (iconElement) {
                        iconElement.style.transform = `translate(-70%, -70%) rotate(${location.heading || 0}deg)`;
                    }

                    // Update popup content
                    existingMarker.setPopupContent(`
                        <strong>Vehicle ID:</strong> ${location.licensePlate}<br>
                        <strong>Speed:</strong> ${location.speed || 0} km/h<br>
                        <strong>Heading:</strong> ${location.heading || 0}°<br>
                        <strong>Ignition:</strong> ${location.ignitionStatus === 1 ? 'On' : 'Off'}
                    `);

                    // Update marker icon (in case ignition status changed)
                    existingMarker.setIcon(createIcon(location));

                    this.markers.push(existingMarker);
                    existingMarkers.delete(location.licensePlate);
                } else {
                    // Create new marker if it doesn't exist
                    const marker = L.marker(newLatLng, {
                        icon: createIcon(location),
                        rotationAngle: location.heading || 0
                    })
                        .bindTooltip(location.licensePlate, {
                            permanent: true,
                            direction: 'top',
                            offset: [0, -25],
                            className: 'custom-tooltip'
                        })
                        .bindPopup(`
                            <strong>Vehicle ID:</strong> ${location.licensePlate}<br>
                            <strong>Speed:</strong> ${location.speed || 0} km/h<br>
                            <strong>Heading:</strong> ${location.heading || 0}°<br>
                            <strong>Ignition:</strong> ${location.ignitionStatus === 1 ? 'On' : 'Off'}
                        `)
                        .addTo(this.map);

                    // Rotate just the triangle part of the marker
                    const iconElement = marker.getElement()?.querySelector('div > div:last-child');
                    if (iconElement) {
                        iconElement.style.transform = `translate(-70%, -70%) rotate(${location.heading || 0}deg)`;
                    }

                    this.markers.push(marker);
                }
            }
        });

        // Remove markers that no longer exist in the new data
        existingMarkers.forEach(marker => {
            marker.remove();
        });

        // Only fit bounds if it's the first time loading markers
        if (this.markers.length > 0 && !this.initialZoomDone) {
            const group = L.featureGroup(this.markers);
            this.map.fitBounds(group.getBounds(), { padding: [50, 50] });
            this.initialZoomDone = true;
        }
    }

    disconnect() {
        // Clear the polling interval when controller is disconnected
        if (this.pollingInterval) {
            clearInterval(this.pollingInterval)
        }

        // Clear the progress bar interval
        if (this.progressInterval) {
            clearInterval(this.progressInterval)
        }

        // Clean up markers
        this.markers?.forEach(marker => marker.remove())

        // Clean up map instance
        if (this.map) {
            this.map.remove()
        }
    }
}