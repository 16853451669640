import {Controller} from "@hotwired/stimulus"

let results = null;

export default class extends Controller {
    static targets = ["query", "source", "result", "overlay", "input"]

    connect() {
        document.addEventListener('keydown', (event) => {
            if (!document.getElementById('search')) return

            // TODO!
            // If button is . and user is not focussed on any input field.
            // if (event.key == ".") {
            //     event.preventDefault()
            //     this.open()
            // }

            if (event.key == "Escape") {
                this.close()
            }
        })
    }

    open() {
        this.overlayTarget.classList.remove('hidden')
        this.element.classList.remove('hidden')
        this.inputTarget.focus()
    }

    search(event) {
        const input = event.target.value

        // Search projects with that input.
        fetch(`/admin/search?query=${input}`, {
            headers: {
                "X-Requested-With": "XMLHttpRequest",
                "Content-Type": "application/json",
            }
        })
            .then(response => response.json())
            .then(data => {
                this.resultTarget.innerHTML = `
                    <div>
                        ${data.map(project => {
                    return `
                                <a href="/admin/projects/${project.id}/operation_groups">
                                    <div class="py-2 font-bold font-brand bg-gray-50 px-2 rounded-md hover:bg-blue-200">
                                            ${project.name}
                                    </div>
                                </a>
                            `
                }).join('')}
                    </div>
                `
            })

        // if Enter, submit.
        if (event.key == "Enter") {
            console.log("ENTER")
        }

        // Rails.fire(this.queryTarget, 'submit')
    }

    removeResults() {
        this.resultTarget.innerHTML = null
    }

    close() {
        // if (event.type == "keydown") {
        //     this.overlayTarget.classList.add('animate-slide-out')
        //     return
        // }

        if (event.target == this.overlayTarget) {
            this.overlayTarget.classList.add('animate-fade-out')

            setTimeout(() => {
                this.overlayTarget.classList.add('hidden')
                this.overlayTarget.classList.remove('animate-fade-out')
            }, 300)
        }
    }
}