import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["frame"];

    connect() {
        console.log("Connected sidebyside");
    }

    load(event) {
        console.log("test")
        event.preventDefault();
        const frameId = "data-frame";
        const url = this.element.dataset.url; // Assuming the URL is stored in a data attribute
        Turbo.visit(url, {frame: frameId});
    }

    clicked() {
        console.log("test sidebyside")
    }

    // Disconnect
    disconnect() {
        console.log("Disconnected sidebyside")
        this.element.remove();
    }
}
