import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["input", "preview"]

  connect() {
    console.log("ScanUploadController connected")
  }

  handleFiles(event) {
    const files = event.target.files
    this.previewTarget.innerHTML = ""

    Array.from(files).forEach(file => {
      const reader = new FileReader()
      reader.onload = e => {
        const preview = `
          <div class="relative">
            <img src="${e.target.result}" class="h-24 w-24 object-cover rounded">
          </div>
        `
        this.previewTarget.insertAdjacentHTML('beforeend', preview)
      }
      
      reader.readAsDataURL(file)
    })
  }

  removeFile(event) {
    const preview = event.target.closest('.relative')
    preview.remove()
    
    const dt = new DataTransfer()
    const files = this.inputTarget.files
    
    Array.from(files).forEach(file => {
      if (file !== event.currentTarget.file) {
        dt.items.add(file)
      }
    })

    this.inputTarget.files = null
  }
}