import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["modal", "content"]

    connect() {
        this.contentTarget.querySelector("input:not([name='authenticity_token'])").focus()
    }

    hideModal() {
        console.log("Hiding modal")
        
        // Remove the src attribute from the frame
        const frame = document.getElementById("modal")
        if (frame) {
            frame.removeAttribute("src")
            frame.innerHTML = ""
        }

        // Add fade out animation
        this.modalTarget.classList.add("animate-fade-out")
        
        // Remove the entire modal after animation
        setTimeout(() => {
            // Remove the modal element
            this.element.remove()
        }, 200)
    }

    submitEnd(e) {
        console.log("Submit end", e.detail)
        if (e.detail.success) {
            this.hideModal()
        }
    }

    closeWithKeyboard(e) {
        if (e.code == "Escape") {
            this.hideModal()
        }
    }

    closeBackground(e) {
        // Only close if clicking the backdrop (not the modal content)
        if (e && (this.modalTarget.contains(e.target) || e.target.dataset.action)) {
            return
        }
        this.hideModal()
    }

    disconnect() {
        console.log("Modal controller disconnected")
        const frame = document.getElementById("modal")
        if (frame) {
            frame.removeAttribute("src")
            frame.innerHTML = ""
        }
    }
}