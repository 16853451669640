import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["progress", "waitingRides", "ongoingRides", "toBeVerifiedRides", "completedRides"]


    // When this is loaded, it will receive the ride information and render the progress chart. The chart is made with Tailwind CSS.
    // it will create one big bar with the total_rides from the ride information.
    // Within there it will create smaller bars with the other attributes from ride_infromation.
    connect() {
        const rideInformation = JSON.parse(this.progressTarget.dataset.rideInformation);
        const totalRides = rideInformation.total_rides;
        const waitingRides = rideInformation.waiting_rides;
        const ongoingRides = rideInformation.ongoing_rides;
        const toBeVerifiedRides = rideInformation.to_be_verified_rides;
        const completedRides = rideInformation.completed_rides;

        const percentages = {
            waitingRides: (waitingRides / totalRides) * 100,
            ongoingRides: (ongoingRides / totalRides) * 100,
            toBeVerifiedRides: (toBeVerifiedRides / totalRides) * 100,
            completedRides: (completedRides / totalRides) * 100
        }

        // Set the width of the progress bars
        this.waitingRidesTarget.style.width = `${percentages.waitingRides}%`;
        this.ongoingRidesTarget.style.width = `${percentages.ongoingRides}%`;
        this.toBeVerifiedRidesTarget.style.width = `${percentages.toBeVerifiedRides}%`;
        this.completedRidesTarget.style.width = `${percentages.completedRides}%`;
    }
}
